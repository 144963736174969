import React from "react";
import VideoCard from "./VideoCard/VideoCard";
import { Container } from "react-bootstrap";
import video1Img from "../../assets/images/video1.png";
import video2Img from "../../assets/images/video2.png";
import video3Img from "../../assets/images/video3.png";
import video4Img from "../../assets/images/video4.png";
import video5Img from "../../assets/images/video5.png";
import video6Img from "../../assets/images/video6.png";
import video7Img from "../../assets/images/video7.png";
import video8Img from "../../assets/images/video8.png";
import video9Img from "../../assets/images/video9.png";
import video10Img from "../../assets/images/video10.png";
import video11Img from "../../assets/images/video11.png";
import video12Img from "../../assets/images/video12.png";
import "./styles.css";

const Index = () => {
  const videoDetail = [
    {
      id: "1",
      title: "Single-Hand Guard Pass  ",
      description:
        "Seize swift victory with the Single-Hand Guard Pass, mastering martial finesse and precision.",
      img: video1Img,
    },
    {
      id: "2",
      title: "Double Hand Guard pass ",
      description:
        "Master precision with the Double Hand Guard Pass, dominating opponents with tactical finesse.",
      img: video2Img,
    },
    {
      id: "3",
      title: "Side Control Fundamental ",
      description:
        "Hone your grappling finesse with Side Control fundamentals, mastering strategic precision.",
      img: video3Img,
    },
    {
      id: "4",
      title: "Americana from Mount Fundamental ",
      description:
        "Perfect your Mount Americana technique, mastering control and submission with finesse.",
      img: video4Img,
    },
    {
      id: "5",
      title: "Trap & Roll (UPA) - Mount Escape  ",
      description:
        "Master strategic evasion with the Trap & Roll (UPA), outmaneuvering opponents with finesse.",
      img: video5Img,
    },
    {
      id: "6",
      title: "Scissors Sweep to Mount ",
      description:
        "Core Jiu Jitsu maneuver utilizing leg control to transition from guard to the mount position. ",
      img: video6Img,
    },
    {
      id: "7",
      title: "Push Kick Sweep to Mount",
      description:
        "Basic Jiu Jitsu technique for transitioning to mount by sweeping the opponent with a push kick",
      img: video7Img,
    },
    {
      id: "8",
      title: "Cross Collar Choke -Close Guard ",
      description:
        "Dominate with the Cross Collar Choke from Close Guard, executing precise control.",
      img: video8Img,
    },
    {
      id: "9",
      title: "Cross Collar Choke - From Mount ",
      description:
        "Harness the Cross Collar Choke from Mount, mastering control and submission with strategic finesse.",
      img: video9Img,
    },
    {
      id: "10",
      title: "Stand to Open the Guard  ",
      description:
        "Master the art of Stand to Open the Guard, breaking through defenses with strategic finesse and precision.",
      img: video10Img,
    },
    {
      id: "11",
      title: "1st Standing Guard Pass ",
      description:
        "Seize dominance with the 1st Standing Guard Pass, asserting control from the start.",
      img: video11Img,
    },
    {
      id: "12",
      title: "2nd Standing Guard Pass   ",
      description:
        "Advance mastery with the 2nd Standing Guard Pass, asserting control with precision.",
      img: video12Img,
    },
  ];


  return (
    <div className="classes-page">
      <Container>

        <div className="video-card-row mt-4 d-grid gap-3">
          {videoDetail.map((item) => (
            <VideoCard
              id={item.id}
              img={item.img}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Index;
