import styled from "styled-components";

export const ExploreContainer = styled.div`
    .image-card {
      margin: 0px 8px;

      .image {
        img {
          width: 100%;
        }
      }
    }
`;
