import React from "react";
import styled from "styled-components";
import videoImg from "../../../assets/images/dot icon.png";

const VideoDescriptionStyled = styled.div`
  padding: 8px 10px;

  .heading {
    color: #0f0f0f;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    margin-bottom: 3px;
  }
  .views-and-likes {

    color: #606060;
    font-size: 9px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 2px;

    img{
      width:15px
    }
  }
  .subheading{
    font-size: 12px;
    padding-top: 5px;
   }
`;
const VideoSubDescription = ({ heading,description,time,views }) => {

  return (
    <VideoDescriptionStyled>
      <h4 className="heading">{heading}</h4>
      <p  className="subheading">Brighton Marina</p>
      <p className="views-and-likes">
{views} <img src={videoImg} alt="."/>{time}
        <p className="description">{description}</p>
      </p>
    </VideoDescriptionStyled>
  );
};

export default VideoSubDescription;
