import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import Dropdown from "react-bootstrap/Dropdown";

import { NavbarContainer } from "./styles";
import logo from "../../assets/svgs/logo.svg";
import { FlexContainer } from "../GlobalStyle";

const AppNavbar = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const NAVBAR_ITEMS = [
    { id: 7, label: "Home", link:"/" },
    { id: 1, label: "Videos",link:"/videos"  },
    { id: 3, label: "Time Table", ref: "timeTableSection" },
    { id: 4, label: "Membership", ref: "membershipSection" },
    { id: 6, label: "Contact Us", ref: "contactUsSection" },
  ];

const handleClick =(item)=>{
if(item.ref){
  scrollToSection(item.ref)
}
else if(item.link) {
  navigate(item.link)
}
}

  const navbarLinks = NAVBAR_ITEMS.map((item) => (
    <div
      className="app-nav-link cursor-pointer "
      key={item.id}
      onClick={()=>handleClick(item)}
      
    >
      {item.label}
    </div>
  ));

  return (
    <NavbarContainer>
      <FlexContainer
        className="container app-navbar-container py-2 px-0"
        justifycontent="space-between"
      >
        <FlexContainer
          className="app-logo gap-3 cursor-pointer"
          onClick={() => navigate("/")}
          justifycontent="space-between"
        >
          <img src={logo} alt="" />
          <h4 className="brand_text">Brighton Marina Jiu Jitsu Academy</h4>
        </FlexContainer>
        <div className="app-navbar gap-4 d-lg-flex d-none">{navbarLinks}</div>

        <Dropdown className="d-lg-none d-block">
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <MenuOutlined />
          </Dropdown.Toggle>

          <Dropdown.Menu className="ps-3">{navbarLinks}</Dropdown.Menu>
        </Dropdown>
      </FlexContainer>
    </NavbarContainer>
  );
};

export default AppNavbar;
